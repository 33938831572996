import React, { useEffect, useState } from 'react'
import { LiveHelpRounded, ContactsRounded } from '@mui/icons-material'

import Banner from '../Banner/Banner.jsx'
import Carousel from 'react-material-ui-carousel'
import s from './Header.styles.js'
import SearchBar from '../SearchBar/SearchBar'
import str from '../../constants/strings.js'

const Header = () => {
    const [banners, setBanners] = useState([])

    useEffect(() => {
        fetch('/api/banner')
            .then((response) => response.json())
            .then((data) => {
                const bannerStrings = String.fromCharCode(...data.data)
                    .split('\n')
                    .filter((bannerString) => bannerString.length !== 0)
                    .filter((bannerString) => bannerString.charAt[0] !== '#')

                setBanners(bannerStrings)
            })
            .catch(() => window.location.assign(str.ERROR_URL))
    }, [])

    useEffect(() => {
        setInterval(() => {
            fetch('/api/banner')
                .then((response) => response.json())
                .then((data) => {
                    const bannerStrings = String.fromCharCode(...data.data)
                        .split('\n')
                        .filter((bannerString) => bannerString.charAt[0] !== '#')
                        .filter((bannerString) => bannerString.length !== 0)

                    setBanners(bannerStrings)
                })
                .catch(() => window.location.assign(str.ERROR_URL))
        }, 1 * 60 * 60 * 1000)
    }, [])

    return (
        <div>
            <s.Header id='header'>
                <s.HeaderItems>
                    <a href='/'>
                        <s.Logo src='/images/home_logo.png' id='logo' alt='MarioStore logo' />
                    </a>
                </s.HeaderItems>
                <s.HeaderItems>
                    <SearchBar />
                </s.HeaderItems>
                <s.HeaderItems>
                    <s.NavbarItems href='/faq'><LiveHelpRounded />FAQ</s.NavbarItems>
                    <s.NavbarItems href='/about'>About</s.NavbarItems>
                    <s.NavbarItems href='/contact'><ContactsRounded />Contact</s.NavbarItems>
                </s.HeaderItems>
            </s.Header>
            <div>
                {
                    banners.length > 0 &&
                    <Carousel
                        animation={'slide'}
                        indicators={false}
                        interval={10000}
                        stopAutoPlayOnHover={true}
                    >
                        {
                            banners.map((banner, index) => {
                                const [type, content, image] = banner.split(',')
                                return <Banner key={index} type={type} content={content} image={image} />
                            })
                        }
                    </Carousel>
                }
            </div>
        </div>
    )
}

export default Header
