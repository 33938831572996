const BACKGROUND_GREY = '#f7f7f7'
const BLACK = '#000000'
const BROWN = '#8a754e'
const GREEN = '#008000'
const LIGHT_GREEN = '#26ff80'
const WHITE = '#ffffff'

const INFO = '#cff4fc'
const WARNING = '#f9ff4f'
const ERROR = '#f7d7da'

export default {
    BACKGROUND_GREY,
    BLACK,
    BROWN,
    ERROR,
    GREEN,
    INFO,
    LIGHT_GREEN,
    WARNING,
    WHITE,
}
