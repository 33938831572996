import styled from 'styled-components'
import divs from '../../components/Common/Divs'

// Animations
const AnimationDuration = {
    scaleUp: '1s',
}
const AnimationDelay = {
    scaleUp: '0s',
}
const ScaleUpAnimationName = 'scaleUp'
const ScaleUpAnimation = `
    @keyframes ${ScaleUpAnimationName} {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes ${ScaleUpAnimationName}Inverted {
        0% {
            transform: scaleX(-1) scale(0);
        }

        100% {
            transform: scaleX(-1) scale(1);
        }
    }

    animation: ${AnimationDuration[ScaleUpAnimationName]} ease-out ${AnimationDelay[ScaleUpAnimationName]} ${ScaleUpAnimationName};
    transform-origin: bottom;
`

const ScaleUpAnimationInverted = `
    ${ScaleUpAnimation}

    animation: ${AnimationDuration[ScaleUpAnimationName]} ease-out ${AnimationDelay[ScaleUpAnimationName]} ${ScaleUpAnimationName}Inverted;
    transform: scaleX(-1);
`

// Components
const Body = styled(divs.Squeeze)`
    text-align: center;
`

const PartyPopper = styled.img`
    ${ScaleUpAnimation}

    height: 15%;
    max-width: 20%;
    width: 15%;
`

const PartyPopperInverted = styled.img`
    ${ScaleUpAnimationInverted}

    height: 15%;
    max-width: 20%;
    width: 15%;
`

const Present = styled.img`
    height: 20%;
    max-width: 25%;
    width: 20%;
`

export default {
    Body,
    PartyPopper,
    PartyPopperInverted,
    Present,
}
