import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants/breakpoints'

const Image = styled.img`
    height: auto;
    max-width: 100%;
    max-height: 100%;
    width: auto/9;
`

const Thumbnail = styled(Image)`
    border-radius: 1rem 1rem 0 0;
    padding-inline: auto;

    &:hover {
        transform: scale(1.03, 1.03);
        transition: 0.4s;
    }
  
    &:not(:hover) {
        transform: scale(1, 1);
        transition: 0.4s;
    }
`

const ProductImage = styled(Image)`
    margin-inline: auto;
    max-width: 500px;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        height: auto;
        max-height: unset;
    }
`

export default {
    Image,
    ProductImage,
    Thumbnail,
}
