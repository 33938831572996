const BASE_URL = 'https://www.mariostoregb.co.uk'
const API_URL = `${BASE_URL}/api`
const CDN_URL = 'https://cdn.mariostoregb.co.uk'
const IMAGES_URL = `${CDN_URL}/images`
const DEFAULT_IMAGE_URL = `${IMAGES_URL}/default.webp`
const MUFFIN_URL = `${IMAGES_URL}/muffin`

const BANNER_URL = `${CDN_URL}/banner`
const BANNER_IMAGE_URL = `${BANNER_URL}/images`

const ERROR_URL = `${BASE_URL}/error`

const TELEPHONE_NUM = '+44 161 399 2435'
const SUPPORT_EMAIL = 'support@mariostoregb.co.uk'

export default {
    API_URL,
    BASE_URL,
    BANNER_URL,
    BANNER_IMAGE_URL,
    CDN_URL,
    DEFAULT_IMAGE_URL,
    ERROR_URL,
    IMAGES_URL,
    MUFFIN_URL,
    SUPPORT_EMAIL,
    TELEPHONE_NUM,
}
