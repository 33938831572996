import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import { MenuItem, Select } from '@mui/material'
import i from '../../components/Common/Inputs'
import s from './Product.styles'
import str from '../../constants/strings'

/*
 * Any references to `parseFloat().toFixed(3)` are a work-around for multiple
 * products with the same price tag. This is appended with the items index so
 * becomes x.xx0, x.xx1, x.xx2, which is NOT reflected in the real price.
 */
const Product = () => {
    const { productId } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [product, setProduct] = useState(null)
    const [price, setPrice] = useState(null)

    useEffect(() => {
        fetch(`/api/products/${productId}`)
            .then((response) => {
                if (response.status && response.status === 404) {
                    return window.location.assign('/404')
                } else if (response.status && response.status === 500) {
                    return window.location.assign(str.ERROR_URL)
                }
                return response.json()
            })
            .then((data) => {
                const tempProduct = data.product
                setProduct(tempProduct)
                setPrice({
                    name: tempProduct.prices[0].name,
                    value: parseFloat(tempProduct.prices[0].value + 0).toFixed(3),
                })
                setIsLoading(false)
            })
            .catch(() => window.location.assign(str.ERROR_URL))
    }, [])

    return (
        <s.ProductPage id='productPage'>
            {
                isLoading &&
                <div>Loading ...</div>
            }
            {
                !isLoading &&
                product == null &&
                <div>Product with id ({productId}) does not exist ...</div>
            }
            {
                !isLoading &&
                product &&
                <s.Product id={productId}>
                    <s.ProductImage src={product.images.length > 0 ? product.images[0] : str.DEFAULT_IMAGE_URL} alt={product.name} />
                    <s.ProductContent>
                        <h2>{product.name} - £{parseFloat(price.value).toFixed(2)}</h2>
                        <s.ProductDescription>{product.description}</s.ProductDescription>
                        <form id='purchase' action={''} method=''>
                            <div>
                                {
                                    product.prices.length > 1 &&
                                        <s.InlineBlock>
                                            <Select
                                                id={`${productId}-price-select`}
                                                defaultValue={parseFloat(product.prices[0].value + 0).toFixed(3)}
                                                value={price.value}
                                                onChange={(event, obj) => {
                                                    const newPrice = product.prices.filter((p) => p.name === obj.key.substring(2))[0]
                                                    let i = -1
                                                    product.prices.forEach((p, index) => {
                                                        if (p.name === obj.key.substring(2)) {
                                                            i = index
                                                        }
                                                    })
                                                    setPrice({ name: newPrice.name, value: parseFloat(newPrice.value + i).toFixed(3) })
                                                }}
                                                form=''
                                                name={`${productId}-price-select`}
                                            >
                                                {
                                                    product.prices.map((price, index) =>
                                                        <MenuItem
                                                            key={price.name}
                                                            value={parseFloat(price.value + index).toFixed(3)}
                                                            aria-selected="false"
                                                        >
                                                            {price.name}
                                                        </MenuItem>
                                                    )
                                                }
                                            </Select>
                                        </s.InlineBlock>
                                }
                                <s.InlineBlock>
                                    <i.TextBox
                                        aria-label='Quantity'
                                        id='quantity'
                                        name={'quantity'}
                                        placeholder='Quantity'
                                        size='small'
                                        type={'number'}
                                        variant='standard'
                                        InputProps={{ inputProps: { min: 1 } }}
                                        required
                                    />
                                </s.InlineBlock>
                            </div>
                        </form>
                        <s.GapBlock>
                            <s.BuyNowButton
                                enabled={false}
                                form=''
                            >
                                TEMP SHUTDOWN
                            </s.BuyNowButton>
                            {/* TODO: Add Cart */}
                            {/* <form id='addToCart' action={`/addToCart/${productId}`} method='POST'>
                                <s.AddToCartButton
                                    enabled={product.quantity > 0}
                                    type='submit'
                                    form='addToCart'
                                >
                                        Add To Cart
                                </s.AddToCartButton>
                            </form> */}
                        </s.GapBlock>
                    </s.ProductContent>
                    {/* TODO: Add details */}
                    <s.Details>
                        <h3>Product Details:</h3>
                        <ul>
                            {product.details.map((detail, index) => <li key={index}>{detail}</li>)}
                        </ul>
                    </s.Details>
                </s.Product>
            }
        </s.ProductPage>
    )
}

export default Product
