import React from 'react'

import s from './Error.styles'
import str from '../../constants/strings'

// TODO: Add error handling
const Error = ({ status, error }) => {
    return (
        <>
            <s.Body>
                <s.MuffinError src={`${str.MUFFIN_URL}/error.webp`} />
                <s.Header1>Well that just happened!</s.Header1>
                <s.Text>
                    Not too sure what happened here, but you can send an e-mail to our support team
                    at <a href={`mailto:${str.SUPPORT_EMAIL}`}>{str.SUPPORT_EMAIL}</a>.
                    <br />
                    <s.AnchorTag href='/'>Go to Home</s.AnchorTag>
                </s.Text>
            </s.Body>
        </>
    )
}

export default Error
