import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants/breakpoints'
import COLORS from '../../constants/colors'

const Header = styled.div`
    background-color: ${COLORS.BACKGROUND_GREY};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: unset;

    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
        grid-template-columns: unset;
        grid-template-rows: 1fr 0.5fr 0.5fr;
    }
`

const HeaderItems = styled.div`
    display: inline-block;
    margin: auto 0;
    padding-block: 1rem;
    text-align: center;
`

const Logo = styled.img`
    margin-inline: auto;
    max-width: 65%;
`

const NavbarItems = styled.a`
    color: ${COLORS.BLACK};
    font-size: larger;
    padding-inline: 1.5rem;
    text-decoration: none;

    @media (max-width: ${BREAKPOINTS.MOBILE_L}) {
        font-size: medium;
        padding-inline: 1rem;
    }
`

export default {
    Header,
    HeaderItems,
    Logo,
    NavbarItems,
}
