import React, { useEffect, useState } from 'react'

import divs from '../../components/Common/Divs'
import ProductCard from '../../components/ProductCard/ProductCard'
import s from './Home.styles'
import str from '../../constants/strings'

const Home = () => {
    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        fetch('/api/products/all')
            .then((response) => {
                if (response.status && response.status === 404) {
                    return window.location.assign('/404')
                } else if (response.status && response.status === 500) {
                    return window.location.assign(str.ERROR_URL)
                }
                return response.json()
            })
            .then((data) => {
                setProducts(Object.entries(data))
                setIsLoading(false)
            })
            .catch(() => window.location.assign(str.ERROR_URL))
    }, [])

    return (
        <s.Home>
            {
                isLoading &&
                <div>Loading ...</div>
            }
            {
                !isLoading &&
                (products === null || products.length === 0) &&
                <divs.AlignCenter><h2>No items to show</h2></divs.AlignCenter>
            }
            {
                !isLoading &&
                products &&
                products.map(([key, product]) =>
                    <ProductCard
                        key={key}
                        id={key}
                        description={product.description}
                        images={product.images}
                        name={product.name}
                        prices={product.prices.map((price) => price.value)}
                        priceNames={product.prices.map((price) => price.name)}
                        quantity={product.quantity}
                    />
                )
            }
        </s.Home>
    )
}

export default Home
