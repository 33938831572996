import React from 'react'

import { CallRounded, ContactsRounded, EmailRounded, HomeRounded, Instagram, LocationOnRounded, StoreRounded, WhatsApp } from '@mui/icons-material'
import a from '../Common/AnchorTags'
import s from './Footer.styles'
import str from '../../constants/strings'
import TikTokSvg from '../TikTok/TikTokSvg'

const Footer = () => {
    return (
        <s.Footer>
            <s.FooterItems>
                <s.FooterList>
                    <s.FooterListItems><p><a.AnchorTag href='/'><HomeRounded />Main Page</a.AnchorTag></p></s.FooterListItems>
                    <s.FooterListItems><p><a.AnchorTag href='https://www.etsy.com/uk/shop/mariostoregb'><StoreRounded />Etsy Store</a.AnchorTag></p></s.FooterListItems>
                    <s.FooterListItems><p><a.AnchorTag href='/contact'><ContactsRounded />Contact Us</a.AnchorTag></p></s.FooterListItems>
                </s.FooterList>
            </s.FooterItems>
            <s.FooterItems>
                <s.FooterList>
                    <s.FooterListItems><p><a.AnchorTag href='https://goo.gl/maps/h9ubMs478197yN597'><LocationOnRounded />50 Store St, Manchester, UK</a.AnchorTag></p></s.FooterListItems>
                    <s.FooterListItems><p><a.AnchorTag href={`tel:${str.TELEPHONE_NUM}`}><CallRounded />{str.TELEPHONE_NUM}</a.AnchorTag></p></s.FooterListItems>
                    <s.FooterListItems><p><a.AnchorTag href={`mailto:${str.SUPPORT_EMAIL}`}><EmailRounded />{str.SUPPORT_EMAIL}</a.AnchorTag></p></s.FooterListItems>
                </s.FooterList>
            </s.FooterItems>
            <s.FooterItems>
                <s.FooterList>
                    <s.FooterListItems><a.WhatsAppButtonLink href='https://wa.me/00447525057118' target='_blank'><WhatsApp /> mariostoregb</a.WhatsAppButtonLink></s.FooterListItems>
                    <s.FooterListItems><a.InstagramButtonLink href='https://www.instagram.com/mariostoregb' target='_blank'><Instagram /> mariostoregb</a.InstagramButtonLink></s.FooterListItems>
                    <s.FooterListItems><a.TikTokButtonLink href='https://www.tiktok.com/@mariostoregb' target='_blank'><TikTokSvg height='50' width='50' /> mariostoregb</a.TikTokButtonLink></s.FooterListItems>
                </s.FooterList>
            </s.FooterItems>
        </s.Footer>
    )
}

export default Footer
