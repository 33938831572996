import React from 'react'

import s from './About.styles'

const About = () => {
    return (
        <s.Body id='aboutPage'>
            <div>
                <h1>About MarioStore</h1>
            </div>
            <div>
                <p>MarioStore is currently a one-person laser engraving and personalisation team based in Manchester, UK.</p>
                <p>My name is Marios, the owner, founder, support, tech, and everything else. I am a Computer Science graduate who is trying to turn a passion into a profession. Tech is cool, but messing around with lasers is cooler 😊!</p>
                <p>The personalisation space is extremely competitive but I am positive that with your help I can achieve my dream!</p>
            </div>
            <h3>~ Mario</h3>
        </s.Body>
    )
}

export default About
