import React, { useState } from 'react'

import { SearchRounded } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import b from '../Common/Buttons'
import i from '../Common/Inputs'
import s from './SearchBar.styles'

const SearchBar = () => {
    const [query, setQuery] = useState('')
    const redirectToSearch = () => {
        window.location.assign(`/search/${query}`)
    }

    const onChangeText = (event) => {
        setQuery(event.target.value)
    }

    return (
        <s.SearchBar>
            <i.TextBox
                id='searchText'
                placeholder='Search...'
                aria-label='Search'
                fullWidth
                variant='standard'
                name='query'
                onChange={onChangeText}
                InputProps={{
                    endAdornment:
                        <InputAdornment position='end'>
                            <b.IconButton
                                id='searchBtn'
                                onClick={redirectToSearch}
                            >
                                <SearchRounded />
                            </b.IconButton>
                        </InputAdornment>,
                }}
            />
        </s.SearchBar>
    )
}

export default SearchBar
