import React from 'react'

import s from './Banner.styles'

const Banner = ({ type, content, image }) => {
    return (
        <div>
            {
                image != null &&
                image !== '' &&
                <s.BannerWithImage image={image} type={type}>{content}</s.BannerWithImage>
            }
            {
                (image == null || image === '') &&
                <s.Banner type={type}>{content}</s.Banner>
            }
        </div>
    )
}

export default Banner
