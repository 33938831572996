import React from 'react'

import s from './SuccessfulPurchase.styles'
import str from '../../constants/strings'

const SuccessfulPurchase = () => {
    return (
        <s.Body>
            <div>
                <div>
                    <s.PartyPopperInverted src={`${str.IMAGES_URL}/party-popper.png`} alt='party popper' />
                    <s.Present src={`${str.IMAGES_URL}/present.png`} alt='present' />
                    <s.PartyPopper src={`${str.IMAGES_URL}/party-popper.png`} alt='party popper' />
                </div>
                <div>
                    <h3>Thank you for your purchase!</h3>
                    <hr />
                    <p>Every order really helps us keep afloat and continue pursuing our dream.</p>
                    <p>From the bottom of our hearts from all of us here at MarioStore ... thank you!</p>
                </div>
            </div>
        </s.Body>
    )
}

export default SuccessfulPurchase
