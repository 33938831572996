import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants/breakpoints'

const Body = styled.div`
    text-align: center;
`

const Header1 = styled.h1`
    font-size: 100pt;
    margin-block: 0;
    transition: ease all 1s;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        font-size: 72pt;
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE_L}) {
        font-size: 60pt;
    }
`

const MuffinNotFound = styled.img`
    height: 15%;
    max-width: 350px;
    transition: ease all 1s;

    @media screen and (max-width: ${BREAKPOINTS.LAPTOP}) {
        max-width: 400px;
        width: unset;
    }

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        max-width: 250px;
        width: unset;
    }
`

export default {
    Body,
    Header1,
    MuffinNotFound,
}
