import React from 'react'

import b from '../Common/Buttons'
import Carousel from 'react-material-ui-carousel'
import i from '../Common/Images'
import p from '../Common/Paragraphs'
import str from '../../constants/strings'
import s from './ProductCard.styles'

const redirectToItem = (id) => {
    window.location.assign(`/products/${id}`)
}

const ProductCard = ({
    id,
    description,
    images,
    name,
    prices,
    priceNames,
    quantity,
}) => {
    let priceText = ''
    const uniquePrices = prices.filter((price, index, list) => list.indexOf(price) === index)

    if (uniquePrices.length === 1) {
        priceText = `£${uniquePrices[0]}`
    } else if (uniquePrices.length > 1) {
        priceText = `£${uniquePrices[0]} - £${uniquePrices[uniquePrices.length - 1]}`
    }

    return (
        <s.Card id={id}>
            <div onClick={() => redirectToItem(id)}>
                {
                    images &&
                    images.length > 1 &&
                    <Carousel>
                        {
                            images.map((url, index) =>
                                <i.Thumbnail key={`${name}-${index}`} src={url} alt={name} />
                            )
                        }
                    </Carousel>
                }
                {
                    images &&
                    images.length === 1 &&
                    <i.Thumbnail key={`${name}-0`} src={images[0]} alt={`Image of ${name}`} />
                }
                {
                    (!images ||
                    images.length === 0) &&
                    <i.Thumbnail key={`${name}-0`} src={str.DEFAULT_IMAGE_URL} alt={name} />
                }
            </div>
            <s.CardText>
                <h4>
                    <s.ProductTitle href={`/products/${id}`}>
                        {name}<br />{priceText}
                    </s.ProductTitle>
                </h4>
                <p.EllipsisText>{description}</p.EllipsisText>
            </s.CardText>
            <s.CardButtons>
                <form id={''} action={''} method=''>
                    <b.PrimaryButton
                        enabled={quantity > 0}
                        type='submit'
                        form={''}
                    >
                        TEMP SHUTDOWN
                    </b.PrimaryButton>
                </form>
            </s.CardButtons>
        </s.Card>
    )
}

export default ProductCard
