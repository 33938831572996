import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants/breakpoints'

const SearchBar = styled.div`
    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
        margin-inline: auto;
        width: 75%;
    }
`

export default {
    SearchBar,
}
