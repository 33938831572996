import styled from 'styled-components'
import { TextField } from '@mui/material'

const FormText = styled.input`
    border: none;
    border-bottom: 1px solid;
    width: 100%;
`

const FormTextArea = styled.textarea`
    border: none;
    border-bottom: 1px solid;
    width: 100%;
`

const TextBox = styled(TextField)`
    ${props => props.fullWidth ? 'width: 100%;' : ''}

    .Mui-focused:after {
        border-bottom: none;
    }
`

export default {
    FormText,
    FormTextArea,
    TextBox,
}
