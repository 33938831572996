import styled from 'styled-components'
import divs from '../../components/Common/Divs'

const Body = styled(divs.Squeeze)`
    text-align: center;
`

export default {
    Body,
}
