import styled from 'styled-components'
import a from '../../components/Common/AnchorTags'

const HyperLink = styled(a.AnchorTag)`
    text-decoration: underline;
`

export default {
    HyperLink,
}
