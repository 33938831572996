import React from 'react'

import s from './NotFound.styles'
import str from '../../constants/strings'

const NotFound = () => {
    return (
        <s.Body>
            <s.MuffinNotFound src={`${str.MUFFIN_URL}/notfound.webp`} />
            <s.Header1>404 Not Found</s.Header1>
            <p>Oops! The page you&rsquo;re looking has been zapped out of existence!</p>
        </s.Body>
    )
}

export default NotFound
