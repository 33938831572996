import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants/breakpoints'

const Button = styled.button`
    color: white;
    padding: 0.4rem 2rem;
    text-decoration: none;
    transition: 0.3s;
    width: max-content;

    &:hover {
        color: black;
    }
`

const IconButton = styled.button`
    background: transparent;
    border: none;
    color: grey;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
        color: black;
    }
`

const PrimaryButton = styled(Button)`
    background-color: ${props => props.disabled ? 'grey' : 'black'};
    border: 0.1rem solid transparent;
    border-radius: 2rem;
    display: inline-block;
    margin-bottom: 0.5rem;

    margin-inline: auto;
    text-align: center;
    text-decoration: none;
    width: 75%;

    &:hover {
        background-color: ${props => props.disabled ? 'grey' : 'white'};
        border: 0.1rem solid black;
    }

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        width: 90%;
    }
`

const SecondaryButton = styled(Button)`
    background-color: white;
    border: 0.1rem solid black;
    border-radius: 2rem;
    color: black;
    display: inline-block;
    margin-bottom: 0.5rem;
    margin-inline: auto;
    text-align: center;
    text-decoration: none;
    width: 75%;

    &:hover {
        background-color: black;
        color: white;
    }

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        width: 90%;
    }
`

export default {
    Button,
    IconButton,
    PrimaryButton,
    SecondaryButton,
}
