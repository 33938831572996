import React from 'react'

import divs from '../../components/Common/Divs'
import s from './FAQ.styles.js'

const FAQ = () => {
    return (
        <divs.Squeeze>
            <div>
                <h1>Frequently Asked Questions:</h1>
                <h2>Shipping and Handling</h2>
                <ul>
                    <li>Products are printed, kept, and engraved in a pet and smoke-free residence.</li>
                    <li>100% recyclable packaging is used wherever possible but some products ship in bubble wrap and plastic packaging if deemed necessary for their protection.</li>
                    <li>Please note that any orders completed after 16:00 UK time will be dispatched on the next available business day.</li>
                    <li>Shipping is currently manually sorted. We try to find the best possible deal from a plethora of shipping couriers including Evri, RoyalMail, Whistl, and others.</li>
                </ul>

                <h2>Privacy</h2>
                <ul>
                    {/* TODO: Some info stored? */}
                    <li>Any pictures or information sent by the buyer will be stored on the cloud and will be used solely as a reference if a job needs/is requested to be redone. Your information will <strong>NOT</strong> be shared with any external parties.</li>
                    <li>Please note that any pictures sent are subject to modification to fit engraving requirements such us:</li>
                    <ul>
                        <li>Greyscaling</li>
                        <li>Scaling</li>
                        <li>Rotation</li>
                        <li>Any other process that would alter the look or feel of the picture</li>
                    </ul>
                </ul>

                <h2>Payment Processing</h2>
                {/* TODO: Obvi something is visible to us? */}
                <p>All payments are managed through <s.HyperLink href="https://www.stripe.com">stripe.com</s.HyperLink>.</p>

                <h2>Processing</h2>
                <p>Products can take anywhere between 1 to 5 business days to be prepared and shipped. This depends on the product and quantity, and any changes to these terms will be communicated to the customer via the contact information provided at checkout.</p>
                <p>
                    Once shipped, the time of arrival to your requested shipping address can vary based on the courier, shipping method, and any unforseen circumstances (such as delays in the sorting center, industrial strikes, etc).
                    <br />
                    Such actions are unfortunately out of our control.
                </p>

                <h2>Returns and Disputes</h2>
                <p>Returns, exchanges, refunds, and cancellations are upon the full discretion of MarioStore. MarioStore holds the rights to refuse any return, exchange, refund, or cancellation request.</p>
                <p>Please note that returns will undisputedly not be accepted for the following items:</p>
                <ul>
                    <li>Custom or personalised orders</li>
                    <li>Perishable products (such as food)</li>
                    <li>Digital downloads</li>
                </ul>
                <p>For returns contact MarioStore through the <s.HyperLink href="/contact">contact form</s.HyperLink> or any of the other listed means of communication within 14 days of delivery.</p>
                <p>Cancellations can only be requested if the item has not been dispatched.</p>
                <p>The buyer is responsible for any return postage costs. Exceptions to this rule are on the full discretion of MarioStore.</p>
            </div>
        </divs.Squeeze>
    )
}

export default FAQ
