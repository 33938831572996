import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants/breakpoints'

const Search = styled.div`
    column-gap: 2rem;
    display: inline-grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    justify-content: center;
    justify-items: center;

    @media (max-width: ${BREAKPOINTS.LAPTOP}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (max-width: ${BREAKPOINTS.TABLET}) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (max-width: ${BREAKPOINTS.MOBILE_L}) {
        grid-template-columns: 1fr;
    }
`

export default {
    Search,
}
