import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants/breakpoints'
import b from '../../components/Common/Buttons'

const AddToCartButton = styled(b.SecondaryButton)`
    margin-inline: unset;
    width: 100%;
`

const BuyNowButton = styled(b.PrimaryButton)`
    margin-inline: unset;
    width: 100%;
`

const Details = styled.div`
    padding-inline: 2rem;
    margin-inline: auto;
    text-align: left;
`

const GapBlock = styled.div`
    display: inline-grid;
    gap: 0.5rem;
`

const InlineBlock = styled.div`
    display: inline-block;
    padding: 0.2rem;
`

const Product = styled.div`    
    text-align: center;
`

const ProductContent = styled.div`
    display: inline-grid;
    gap: 1rem;
    max-width: 35%;
    text-align: left;
    vertical-align: middle;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        max-width: 85%;
    }
`

const ProductDescription = styled.div`
    white-space: pre-wrap;
`

const ProductImage = styled.img`
    height: auto;
    max-height: 100%;
    max-width: 400px;
    padding-inline: 1rem;
    vertical-align: middle;
    width: auto/9;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        height: auto;
        max-height: unset;
        max-width: 85%;
    }
`

const ProductPage = styled.div`
    margin-block: 0.5rem;
`

export default {
    AddToCartButton,
    BuyNowButton,
    Details,
    GapBlock,
    InlineBlock,
    Product,
    ProductContent,
    ProductDescription,
    ProductImage,
    ProductPage,
}
