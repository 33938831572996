import styled from 'styled-components'

import i from '../../components/Common/Inputs'

const FormText = styled(i.FormText)`
    margin-block: 0.5rem;
`

const FormTextArea = styled(i.FormTextArea)`
    margin-block: 0.5rem;
`

export default {
    FormText,
    FormTextArea,
}
