import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import divs from '../../components/Common/Divs'
import ProductCard from '../../components/ProductCard/ProductCard'
import s from './Search.styles'
import str from '../../constants/strings'

const Search = () => {
    const { query } = useParams()
    const [products, setProducts] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const searchOptions = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query }),
        }

        fetch('/api/search', searchOptions)
            .then((response) => response.json())
            .then((data) => {
                setProducts(Object.entries(data))
                setIsLoading(false)
            })
            .catch(() => window.location.assign(str.ERROR_URL))
    }, [])

    return (
        <>
            {
                isLoading &&
                <div>Loading ...</div>
            }
            {
                !isLoading &&
                (products === null || products.length === 0) &&
                <divs.AlignCenter><h2>No items to show</h2></divs.AlignCenter>
            }
            {
                !isLoading &&
                products &&
                <s.Search>
                    {products.map(([key, product]) =>
                        <ProductCard
                            key={key}
                            id={key}
                            description={product.description}
                            images={product.images}
                            name={product.name}
                            prices={product.prices.map((price) => price.value)}
                            priceNames={product.prices.map((price) => price.name)}
                            quantity={product.quantity}
                        />
                    )}
                </s.Search>
            }
        </>
    )
}

export default Search
