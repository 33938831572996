import styled from 'styled-components'
import COLORS from '../../constants/colors'
import s from '../../constants/strings'

const Banner = styled.div`
    background-color: ${props => {
        switch (props.type) {
        case 'info': return COLORS.INFO
        case 'warning': return COLORS.WARNING
        case 'error': return COLORS.ERROR
        case 'success': return COLORS.LIGHT_GREEN
        default: return COLORS.WHITE
        }
    }};
    color: ${COLORS.BLACK};
    font-size: larger;
    padding-block: 1rem;
    text-align: center;
`

const BannerWithImage = styled(Banner)`
    background-color: unset;
    background-image: url(${s.BANNER_IMAGE_URL}/${props => props.image});
`

export default {
    Banner,
    BannerWithImage,
}
