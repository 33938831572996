import styled from 'styled-components'
import { BREAKPOINTS } from '../../constants/breakpoints'

const AlignCenter = styled.div`
    text-align: center;
`

const MarginInlineAuto = styled.div`
    margin-inline: auto;
`

const Squeeze = styled.div`
    display: grid;
    margin-inline: auto;
    padding-inline: 3rem;
    row-gap: 0.5rem;

    @media screen and (max-width: ${BREAKPOINTS.TABLET}) {
        padding-inline: 1rem;
    }
`

export default {
    AlignCenter,
    MarginInlineAuto,
    Squeeze,
}
