import React from 'react'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Main from '../../views/Main/Main'

import s from './App.styles'

const App = () => {
    return (
        <s.App>
            <Header />
            <Main />
            <Footer />
        </s.App>
    )
}

export default App
