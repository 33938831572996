import React from 'react'

import { Routes, Route } from 'react-router-dom'
import About from '../About/About'
import Contact from '../Contact/Contact'
import Error from '../Error/Error'
import FAQ from '../FAQ/FAQ'
import Home from '../Home/Home'
import NotFound from '../NotFound/NotFound'
import Product from '../Product/Product'
import SuccessfulPurchase from '../SuccessfulPurchase/SuccessfulPurchase'
import Search from '../Search/Search'

import s from './Main.styles.js'

const Main = () => {
    return (
        <s.Main>
            <Routes>
                <Route
                    path='/success'
                    element={<SuccessfulPurchase />}
                />
                <Route
                    path='/faq'
                    element={<FAQ />}
                />
                <Route
                    path='/about'
                    element={<About />}
                />
                <Route
                    path='/contact'
                    element={<Contact />}
                />
                <Route
                    path='/search'
                    element={<Home />}
                />
                <Route
                    path='/search/:query'
                    element={<Search />}
                />
                <Route
                    path='/products'
                    element={<Home />}
                />
                <Route
                    path='/products/:productId'
                    element={<Product />}
                />
                <Route
                    path='/error'
                    element={<Error />}
                />
                <Route
                    path='/'
                    element={<Home />}
                    errorElement={<></>}
                />
                <Route
                    path='/*'
                    element={<NotFound />}
                />
            </Routes>
        </s.Main>
    )
}

export default Main
