import React from 'react'

import { Instagram } from '@mui/icons-material'
import a from '../../components/Common/AnchorTags'
import b from '../../components/Common/Buttons'
import Banner from '../../components/Banner/Banner'
import divs from '../../components/Common/Divs'
import s from './Contact.styles'
import str from '../../constants/strings'
import TikTokSvg from '../../components/TikTok/TikTokSvg'

const Contact = () => {
    const queryParams = document.location.search.slice(1).split('&')
    let emailSuccess = false
    for (const param of queryParams) {
        const [key, value] = param.split('=')
        if (key === 'email') {
            emailSuccess = value === 'success'
        }
    }

    return (
        <divs.Squeeze>
            {
                emailSuccess && (
                    <Banner type={'success'} content={'Email sent successfully!'} />
                )
            }
            <div>
                <h1>Operational Hours</h1>
                <ul>
                    <li>Monday - Friday: 12:00 - 17:00</li>
                    <li>Saturday - Sunday: CLOSED</li>
                </ul>
                <p>Holiday hours are communicated via our Instagram / TikTok page, and the website banner.</p>
                <p>Follow us on social media to be the first to know of any upcoming announcements, deals, and more!</p>
                <a.InstagramButtonLink href='https://www.instagram.com/mariostoregb' target='_blank'><Instagram /> mariostoregb</a.InstagramButtonLink>
                <a.TikTokButtonLink href='https://www.tiktok.com/@mariostoregb' target='_blank'><TikTokSvg height='50' width='50' /> mariostoregb</a.TikTokButtonLink>
                <p>or contact us directly via e-mail at <a.AnchorTag href={`mailto:${str.SUPPORT_EMAIL}`}>{str.SUPPORT_EMAIL}</a.AnchorTag>, or by phone at <a.AnchorTag href={`tel:${str.TELEPHONE_NUM}`}>{str.TELEPHONE_NUM}</a.AnchorTag>.</p>
            </div>
            <divs.MarginInlineAuto id='contactFormDiv'>
                <divs.AlignCenter>
                    <h1>Contact Us</h1>
                    <h3>When in doubt, reach out!</h3>
                </divs.AlignCenter>
                <div>
                    <div>
                        <form id='contact' method='POST' action='/api/contact'>
                            <strong>Full Name:</strong>
                            <s.FormText type='text' name='fullName' id='name' />
                            <strong>E-Mail Address:</strong>
                            <s.FormText type='email' name='email' id='email' />
                            <strong>Message:</strong>
                            <s.FormTextArea name='message' id='message' cols='30' rows='10' />
                        </form>
                    </div>
                    <divs.AlignCenter>
                        <b.PrimaryButton
                            type='submit'
                            form='contact'
                        >
                            Submit
                        </b.PrimaryButton>
                    </divs.AlignCenter>
                </div>
            </divs.MarginInlineAuto>
        </divs.Squeeze>
    )
}

export default Contact
