import styled from 'styled-components'

const EllipsisText = styled.p`
    -webkit-line-clamp: ${props => props.lines ? props.lines : '5'};
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
`

export default {
    EllipsisText,
}
