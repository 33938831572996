import { BREAKPOINTS } from '../../constants/breakpoints'
import styled from 'styled-components'
import COLORS from '../../constants/colors'

const Footer = styled.div`
    background-color: ${COLORS.BACKGROUND_GREY};
    color: ${COLORS.BLACK};
    text-align: center;
    width: 100%;
    
    // bottom: 0;
    // position: fixed;
`

const FooterItems = styled.div`
    display: inline-block;

    @media (max-width: ${BREAKPOINTS.TABLET}) {
        width: 100%;
    }
`

const FooterList = styled.ul`
    list-style: none;
    padding-inline: 8rem;
    text-align: left;

    @media (max-width: ${BREAKPOINTS.LAPTOP_L}) {
        padding-inline: 1rem;
        text-align: center;
    }
`

const FooterListItems = styled.li`
    color: ${COLORS.BLACK};
    font-size: large;
    text-decoration: none;
`

export default {
    Footer,
    FooterItems,
    FooterList,
    FooterListItems,
}
