import divs from '../Common/Divs'
import styled from 'styled-components'

const Card = styled.div`
    border-radius: 1rem;
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0,0,0,0.3);
    margin: 1rem;
    width: 80%;
`

const CardButtons = styled(divs.AlignCenter)`
    margin: 1rem;
`

const CardText = styled.div`
    padding: 0.1rem 1rem;
    
    h4 {
        margin-block: 0.5rem;
    }
`

const ProductTitle = styled.a`
    background: transparent;
    color: grey;
    font-weight: bold;
    transition: 0.3s;

    &:hover {
        color: black;
    }
`

export default {
    Card,
    CardButtons,
    CardText,
    ProductTitle,
}
