import styled from 'styled-components'
import COLORS from '../../constants/colors'

const ButtonLink = styled.a`
    color: white;
    padding: 0.4rem 2rem;
    text-decoration: none;

    &:hover {
        color: black;
    }
`

const AnchorTag = styled.a`
    color: ${COLORS.BLACK};
    font-size: large;
    font-weight: 600;
    text-decoration: none;

    &:hover {
        color: ${COLORS.BROWN};
        transition-duration: 0.5s;
    }
`

// Template - Non-exported
const SocialButtonLink = styled(ButtonLink)`
    background-size: 200% auto;
    border-radius: 10px;
    display: inline-block;
    font-size: large;
    margin: 10px;
    text-align: center;
    text-decoration: none;
    width: max-content;

    &:hover {
        background-position: right center;
        color: white;
    }
`

const InstagramButtonLink = styled(SocialButtonLink)`
    background-image: linear-gradient(to right, #f7ff00 0%, #db36a4  51%, #f7ff00  100%);

    &:hover {
        color: white;
    }
`

const TikTokButtonLink = styled(SocialButtonLink)`
    background-image: linear-gradient(to right, #FC354C 0%, #0ABFBC  51%, #FC354C  100%);

    &:hover {
        color: white;
    }
`

const WhatsAppButtonLink = styled(SocialButtonLink)`
    background-image: linear-gradient(to right, #27d267 0%, #ffffff  51%, #27d267  100%);

    &:hover {
        color: white;
    }
`

export default {
    AnchorTag,
    ButtonLink,
    InstagramButtonLink,
    TikTokButtonLink,
    WhatsAppButtonLink,
}
